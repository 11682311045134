const env = process.env.NODE_ENV || 'developmen'; // 修正了 'developmen' 的拼写错误

const config = {
  development: {
    AVATAR_BASE_URL: 'http://localhost/uploads/teacher_avatars',
    API_BASE_URL: 'http://localhost/api',
    STATIC_BASE_URL: 'http://localhost',
    TEACHER_IMAGE_BASE_URL: 'http://localhost/teacher_report_image',
    STUDENT_IMAGE_BASE_URL: 'http://localhost/student_report_image',
    // STUDENT_AUDIO_BASE_URL: 'http://localhost/student_audio_homework',
    SOUND_EFFECT_BASE_URL: 'http://localhost/sound_effect',
    TEACHER_FEEDBACK_BASE_URL: 'http://localhost/teacher_feedback',
    NOTIFICATION_API_URL: 'http://localhost:8080/api/notify'  // 本地开发环境的通知 API
  },
  // production: {
  //   AVATAR_BASE_URL: 'https://booking.teacher.theoryabc.com/uploads/teacher_avatars',
  //   API_BASE_URL: 'https://booking.teacher.theoryabc.com/api',
  //   STATIC_BASE_URL: 'https://booking.teacher.theoryabc.com',
  //   TEACHER_IMAGE_BASE_URL: 'https://booking.teacher.theoryabc.com/teacher_report_image',
  //   STUDENT_IMAGE_BASE_URL: 'https://booking.teacher.theoryabc.com/student_report_image',
  //   // STUDENT_AUDIO_BASE_URL: 'https://booking.teacher.theoryabc.com/student_audio_homework',
  //   SOUND_EFFECT_BASE_URL: 'https://booking.teacher.theoryabc.com/sound_effect',
  //   TEACHER_FEEDBACK_BASE_URL: 'https://booking.teacher.theoryabc.com/teacher_feedback',
  //   NOTIFICATION_API_URL: 'https://teacher.theoryabc.com/api/notify'  // 上海服务器的通知 API
  // },
  production: {  // 新增的 Hongkong2 配置
    AVATAR_BASE_URL: 'https://booking.theoryabc.com.cn/uploads/teacher_avatars',
    API_BASE_URL: 'https://booking.theoryabc.com.cn/api',
    STATIC_BASE_URL: 'https://booking.theoryabc.com.cn',
    TEACHER_IMAGE_BASE_URL: 'https://booking.theoryabc.com.cn/teacher_report_image',
    STUDENT_IMAGE_BASE_URL: 'https://booking.theoryabc.com.cn/student_report_image',
    // STUDENT_AUDIO_BASE_URL: 'https://booking.theoryabc.com.cn/student_audio_homework',
    SOUND_EFFECT_BASE_URL: 'https://booking.theoryabc.com.cn/sound_effect',
    TEACHER_FEEDBACK_BASE_URL: 'https://booking.theoryabc.com.cn/teacher_feedback',
    NOTIFICATION_API_URL: 'https://booking.theoryabc.com.cn/api/notify'  // 香港服务器的通知 API
  }
};

export default config[env];